<script lang="ts" setup>
const isMenuActive = ref(false);
const localePath = useLocalePath();

const { t } = useI18n({
  useScope: "local",
});

const toggleMenu = () => {
  isMenuActive.value = !isMenuActive.value;
};
</script>

<template>
  <header class="dealer-header">
    <div class="dealer-header__content container">
      <div class="left">
        <NuxtLink
          :to="localePath('/guest/cars')"
          class="logo"
        >
          <img
            src="~/assets/img/logo.png"
            alt="AutoGeorgia"
          >
        </NuxtLink>
        <AppNavBarMenu />
      </div>
      <div class="right">
        <AppLangSwitcher />
        <AppUserAuth />
      </div>

      <button
        class="btn-menu"
        :class="{ active: isMenuActive }"
        @click="toggleMenu()"
      >
        <span />
      </button>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.dealer-header {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(31, 22, 59, 0.07);

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .left {
    .logo {
      max-width: 123px;
      margin-right: 30px;
    }
  }

  .right {
    @media only screen and (max-width: $tablet) {
      display: none;
    }

    .profile-wrapper {
      display: flex;
      align-items: center;

      .avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .info-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 10px;

        .status {
          font-weight: 700;
          font-size: 10px;
        }

        .user-name {
          position: relative;
          top: 2px;
          font-size: 14px;
        }
      }

      .btn-arrow {
        position: relative;

        &:hover::before {
          opacity: 1;
        }

        &::before {
          position: absolute;
          content: "";
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: rgba(45, 45, 134, 0.1);
          opacity: 0;
          transition: opacity 0.3s;
        }
      }
    }
  }

  .btn-menu {
    display: none;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 30px;

    @media only screen and (max-width: $tablet) {
      display: flex;
    }

    &.active {
      span {
        opacity: 0;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg) translate(-1px, -1px);
      }
    }

    span {
      height: 2px;
      width: 100%;
      background: var(--text-color-1);
      // transform: translateY(-25%);
      transition: opacity 0.2s;
    }

    &::before,
    &::after {
      position: absolute;
      content: "";
      top: 5px;
      width: 100%;
      height: 2px;
      background: var(--text-color-1);
      transform-origin: top left;
      transition: transform 0.35s;
    }

    &::after {
      top: auto;
      bottom: 5px;
    }
  }
}

.mobile-menu {
  position: fixed;
  z-index: 9;
  display: none;
  flex-direction: column;
  align-items: center;
  left: 0;
  top: 50px;
  right: 0;
  bottom: 0px;
  height: unset;
  background: #fff;
  padding: 40px 10px;

  opacity: 0;
  transform: translateX(-100%);
  visibility: hidden;
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s;

  @media only screen and (max-width: $tablet) {
    display: flex;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  .nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;

    .user {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      border-bottom: 1px solid var(--text-color-1);

      &-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .avatar {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .user-name {
          position: relative;
          top: 2px;
          margin: 0 10px;
          font-size: 14px;
          font-weight: 600;
        }
      }

      &-nav {
        padding: 0 10px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      height: 34px;
      width: fit-content;
      border-radius: 6px;
      padding: 0 10px;
      font-size: 14px;
      color: var(--text-color-1);
      margin-bottom: 5px;

      &:hover {
        background: rgba(45, 45, 134, 0.05);
      }

      &.router-link-active {
        background: rgba(45, 45, 134, 0.05);
      }

      img {
        width: 12px;
        height: 12px;
        margin-right: 7px;
      }
    }
  }

  .lang-switcher-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    height: 40px;
    margin-top: 40px;
    padding: 0 20px;
    border-top: 1px solid var(--text-color-1);
    border-bottom: 1px solid var(--text-color-1);
  }
}
</style>
