<script lang="ts" setup>


</script>

<template>
  <div class="dealer">
    <div class="header-wrapper">
      <LayoutGuestHeader />
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .dealer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 50px;

    .header-wrapper {
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
    }
  }

</style>
