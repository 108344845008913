<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<NavBarMenuProps>(), {
  menuItems: []
})
import { toRefs as __MACROS_toRefs } from "vue";
import { useAuthStore } from "../../stores/authUser";

const { t } = useI18n({
  useScope: "global",
});
const localePath = useLocalePath();
const authStore = useAuthStore();

interface NavBarMenuProps {
  menuItems?: object[]
}

const { menuItems } = __MACROS_toRefs(__MACROS_props)

// const menuItems = computed(() => {
//   const menu = [];
//   if (authStore.loggedIn) {
//     menu.push({
//       label: t("nav.calculator"),
//       to: localePath("/dealer/calculator"),
//     });
//     menu.push({
//       label: t("nav.cars"),
//       to: localePath("/dealer/cars"),
//     });
//     menu.push({
//       label: t("nav.requestedServices"),
//       to: localePath(`/dealer/requested-services`),
//     });
//   }
//
//   if (!authStore.loggedIn) {
//     menu.push({
//       label: t("nav.cars"),
//       to: localePath("/guest/cars"),
//     });
//   }
//
//   return menu;
// });
</script>

<template>
  <nav class="nav">
    <NuxtLink
      v-for="item in menuItems"
      :key="item.label"
      class="nav-item"
      :to="item.to"
    >
      {{ item.label }}
    </NuxtLink>
  </nav>
</template>

<style lang="scss" scoped>
.nav {
  display: flex;
  align-items: center;
  height: 100%;

  @media only screen and (max-width: $tablet) {
    display: none;
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;
    padding: 0 20px;
    transition: background-color 0.3s;
    color: var(--text-color-1);

    &:hover {
      background: rgba(45, 45, 134, 0.05);
    }

    &.router-link-active::after {
      opacity: 1;
    }

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(58.17deg, #2d2d86 0.79%, #f0494f 100%);
      border-radius: 5px;
      opacity: 0;
      transition: opacity 0.3s;

      @media only screen and (max-width: $tablet) {
        display: none;
      }
    }
  }
}
</style>
